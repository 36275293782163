<template>
    <v-main>
        <div class="main-content">
        <router-view>
            
        </router-view>
    </div>
    </v-main>
</template>

<script>
    export default {
        name: 'DepositBonuses',
        beforeRouteEnter (to, from, next) {
            next(vm =>
            {
                vm.$store.commit('setPageName', 'dep_bonuses');
            }
            )
        }
    }
</script>

<style lang="scss" scoped>

</style>